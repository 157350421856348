import React, { useMemo } from "react";
import { Card, Button, Box, Typography } from "@mui/material";
import PlanFeature from "../PlanFeature";
import { useSelector } from "react-redux";

const CARD_CONFIG = {
  bgColor: "#B6C5EA80",
  textColor: "#000000",
  borderColor: "#63B8A7",
  btnTextColor: "#fff",
  btnBgColor: "#63B8A7"
};

const PlanCard = ({ plan, handleChoosePlan }) => {
  const { sessionInfo = {} } = useSelector((state) => state.account);
  const { currentUser = {} } = sessionInfo;

  const isSelected = useMemo(() => {
    const {
      isPromoted,
      planning_subscription,
      post_death_subscription,
      products = [],
    } = currentUser;

    if (isPromoted) return true;

    // const planningProducts = products.filter((it) => it.category === 'planning');
    // const postDeathProducts = products.filter((it) => it.category === 'post_death');
    const productIds = products.map(it => it.id);

    if (plan?.id === 'plan-free') {
      return true;
    } else if (plan?.id === 'post-death-free') {
      return true;
    } else if (plan?.id === 1) {
      return planning_subscription?.status === 'active';
    } else if (plan?.id === 5) {
      return post_death_subscription?.status === 'active';
    }
    
    if (plan.subPlans) {
      const subPlanIds = plan.subPlans.map(plan => plan.id);
      return subPlanIds.some(id => productIds.includes(id));
    }

    return productIds.includes(plan?.id);
  }, [currentUser, plan]);

  return (
    <Card
      sx={{
        borderRadius: "10px",
        margin: plan.isPopular ? "0px 0px 36px 0px" : "37px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10);"
      }}
    >
      <Box>
        {plan.isPopular && (
          <Box sx={{ background: "#4B6EAE", py: "5px" }}>
            <Typography
              variant="h6"
              sx={{ color: "#fff", textAlign: "center" }}
            >
              Most Popular
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            py: "10px",
            textAlign: "center",
            backgroundColor: CARD_CONFIG?.bgColor,
            color: CARD_CONFIG?.textColor
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "600", fontSize: "22px" }}>
            {plan.title}
          </Typography>
        </Box>

        {!plan.subPlans && (
          <Box sx={{ padding: "0px 12px" }}>
            <Box
              sx={{
                textAlign: "center",
                padding: "20px",
                minHeight: "60px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <Typography
                variant="h3"
                color="primary.light"
                sx={{ textAlign: "center", fontSize: "22px" }}
              >
                {plan.priceLabel}
              </Typography>
              <Typography
                variant="h7"
                color="grey.700"
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                {plan.priceDesc}
              </Typography>
            </Box>

            {plan.features && (
              <Box sx={{}}>
                <PlanFeature features={plan.features} />
              </Box>
            )}
          </Box>
        )}
      </Box>

      {plan.btnTitle && !isSelected && (
        <Box sx={{ padding: "20px" }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleChoosePlan(plan)}
            sx={{
              border: `1px solid ${CARD_CONFIG.borderColor}`,
              color: CARD_CONFIG.btnTextColor,
              background: CARD_CONFIG.btnBgColor
            }}
          >
            {plan.btnTitle}
          </Button>
        </Box>
      )}

      {plan.subPlans && plan.subPlans.map((subPlanItem, index) => (
        <Box key={index}>
          <Box sx={{ padding: "0px 20px", mb: "20px" }}>
            <Box
              sx={{
                textAlign: "center",
                padding: "20px",
                minHeight: "60px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <Typography
                variant="h3"
                color="primary.light"
                sx={{ textAlign: "center", fontSize: "22px" }}
              >
                {subPlanItem.priceLabel}
              </Typography>

              <Typography
                variant="h6"
                color="grey.700"
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  textAlign: "center"
                }}
              >
                {subPlanItem.priceDesc}
              </Typography>
            </Box>

            <PlanFeature features={subPlanItem.features} />

            {!isSelected && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleChoosePlan(subPlanItem)}
                sx={{
                  border: `1px solid ${CARD_CONFIG.borderColor}`,
                  color: CARD_CONFIG.btnTextColor,
                  background: CARD_CONFIG.btnBgColor
                }}
              >
                {subPlanItem.btnTitle}
              </Button>
            )}
          </Box>

          {index < plan.subPlans.length - 1 && (
            <Box sx={{ display: "flex", alignItems: "center", px: "20px" }}>
              <Box sx={{ height: "1px", background: "#D7D7D7", flex: 1 }} />

              <Box sx={{ px: "20px" }}>
                <Typography variant="h7" sx={{ color: "#818181" }}>
                  OR
                </Typography>
              </Box>

              <Box sx={{ height: "1px", background: "#D7D7D7", flex: 1 }} />
            </Box>
          )}
        </Box>
      ))}
    </Card>
  );
};

export default PlanCard;
