import React, { useMemo, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { sha256 } from "js-sha256";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../Form/FormContainer";
import Input from "../Form/Input";
import Checkbox from "../Form/Checkbox";
import MobileInput from "../Form/MobileInput";
import VerificationModal from "../../Pages/AuthV2/Login/Verification";
import { Link, useLocation } from "react-router-dom";
import {
  createQualtricsUser,
  deleteCognitoUser,
  deleteQualtricsUser,
  getUserRegister,
  postHSLog,
} from "../../Services/User";
import * as Yup from "yup";
import Amplify from "aws-amplify";
import {
  SIGNUP_SOURCE_FACEBOOK,
  SIGNUP_SOURCE_FREDHUTCH,
  SIGNUP_SOURCE_GOOGLE,
  SIGNUP_SOURCE_HOSPICEFLYER,
  SIGNUP_SOURCE_LANDING,
  SIGNUP_SOURCE_NEWSMAX,
  SOURCE_MAP,
} from "../../Assets/Consts/contact";
import { isCompanyEmail } from "../../utils";
import mixpanel from "../../mixpanel";
import { loginStart } from "../../Redux-Saga/Redux/account";
import {
  SIGN_UP_METHOD_FLOW,
  SIGN_UP_METHOD_NORMAL,
} from "../../Assets/Consts";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const phoneRegExp = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const FLOW_STEP_INFO = 0;
const FLOW_STEP_SECURITY = 1;

const SignUpForm = ({
  isPromoUser,
  method,
  hubspotId = null,
  qualtricsId = null,
  initialData = {},
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const source = params.get("utm_source") || sessionStorage.getItem("utm_source");
  const term = params?.get("utm_term") || '';
  const { referer } = useSelector((state) => state.account);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First name is required.")
      .max(40, "First name is too long.")
      .matches(/^[a-zA-Z][A-Za-z0-9_.' -]*$/, "Please provide valid first name."),
    lastName: Yup.string()
      .required("Last name is required.")
      .max(40, "Last name is too long.")
      .matches(/^[a-zA-Z][A-Za-z0-9_.' -]*$/, "Please provide valid last name."),
    mobile: Yup.string()
      .required("Phone number is required.")
      .matches(phoneRegExp, "Please provide valid phone number."),
    email: Yup.string()
      .nullable()
      .trim()
      .required("Email is required.")
      .matches(emailRegExp, "Please provide valid email."),
    password: Yup.string()
      .trim()
      .required("Password is required.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&~/`£])[A-Za-z\d@$!%*#?&~/`£]{8,}$/,
        "Your password must contain 8 characters with at least one uppercase letter, one lowercase letter, one number and one special character."
      ),
    repeatPassword: Yup.string()
      .required("Please provide your repeat password.")
      .oneOf([Yup.ref("password"), null], "Passwords must match."),
    agree: Yup.bool().oneOf([true], "Term & condition is required."),
  });

  const validationInfoSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First name is required.")
      .max(40, "First name is too long.")
      .matches(/^[a-zA-Z][A-Za-z0-9_.' -]*$/, "Please provide valid first name."),
    lastName: Yup.string()
      .required("Last name is required.")
      .max(40, "Last name is too long.")
      .matches(/^[a-zA-Z][A-Za-z0-9_.' -]*$/, "Please provide valid last name."),
    email: Yup.string()
      .nullable()
      .trim()
      .required("Email is required.")
      .matches(emailRegExp, "Please provide valid email."),
  });

  const validationSecuritySchema = Yup.object().shape({
    mobile: Yup.string()
      .required("Phone number is required.")
      .matches(phoneRegExp, "Please provide valid phone number."),
    password: Yup.string()
      .trim()
      .required("Password is required.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Your password must contain 8 characters with at least one uppercase letter, one lowercase letter, one number and one special character."
      ),
    repeatPassword: Yup.string()
      .required("Please provide your repeat password.")
      .oneOf([Yup.ref("password"), null], "Passwords must match."),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    mobile: "+1",
    email: "",
    password: "",
    repeatPassword: "",
    promoCode: isPromoUser ? "pro_sidfslsl" : "",
    agree: false,
    qualtrics_user_id: qualtricsId,
    ...initialData,
  };

  const initialInfoValues = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const initialSecurityValues = {
    password: "",
    repeatPassword: "",
    mobile: "+1",
  };

  const [loading, setLoading] = useState(false);
  const [mfaUser, setMfaUser] = useState();
  const [showVerification, setShowVerification] = useState(false);
  const [signupPayload, setSignupPayload] = useState();
  const [flowStep, setFlowStep] = useState(FLOW_STEP_INFO);
  const [info, setInfo] = useState(null);
  const [hsId, setHSId] = useState(hubspotId);
  const dispatch = useDispatch();

  const handleFormSubmit = async (values) => {
    const { firstName, lastName, mobile, email, password, promoCode } = method === SIGN_UP_METHOD_FLOW ? { ...info, ...values } : values;

    try {
      if (hsId) await postHSLog({ id: hsId, phone: mobile });

      setLoading(true);
      setSignupPayload({
        firstName,
        lastName,
        email,
        mobile,
        password,
        promoCode,
      });

      if (qualtricsId) {
        localStorage.setItem("qualtricsId", qualtricsId);
      } else {
        const result = await createQualtricsUser({ email });
        const userId = result?.data?.data;
        localStorage.setItem("qualtricsId", userId);
      }

      await Amplify.Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          phone_number: mobile,
          given_name: firstName,
          middle_name: "",
          family_name: lastName,
        },
      });

      const user = await Amplify.Auth.signIn({ username: email, password });
      setMfaUser(user);
      setShowVerification(true);
    } catch (err) {
      window.scrollTo(0, 0);
      const errorMsg = err?.message || err?.error?.message || err?.code;
      toast.error(errorMsg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("login failed", err.code);
    } finally {
      setLoading(false);
    }
  };

  const handleSendFBEvent = async (attributes) => {
    try {
      const API_VERSION = "v19.0";
      const PIXEL_ID = "1049425119681324";
      const api_url = `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${process.env.REACT_APP_FB_EVENT_TOKEN}`;
      const payload = {
        data: [
          {
            event_name: "Sign Up",
            event_time: Math.round(new Date().getTime() / 1000),
            action_source: "website",
            user_data: {
              em: sha256(attributes.email),
              ph: sha256(attributes.phone_number),
            },
            custom_data: {
              email: attributes.email,
              name: `${attributes.given_name} ${attributes.family_name}`,
              phone: attributes.phone_number,
              source: sourceName,
            },
          },
        ],
      };

      await axios.post(api_url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log("error ---", error);
    }
  };

  const handleClose = async () => {
    try {
      if (loading) return;
      setShowVerification(false);
      setLoading(true);

      // delete user from cognito
      await deleteCognitoUser({ email: signupPayload?.email });

      // delete user from qualtrics
      const qualtricsId = localStorage.getItem("qualtricsId");
      if (qualtricsId) deleteQualtricsUser(qualtricsId);
    } catch (e) {
      console.log("Failed to remove user from cognito", e);
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setLoading(true);
      const { email, password } = signupPayload;
      const user = await Amplify.Auth.signIn({ username: email, password });
      setMfaUser(user);

      toast.success(`We've resent the verification code to your phone`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.error(`Failed to resend the verification code to your phone`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("Failed resend: ", err);
    } finally {
      setLoading(false);
    }
  };

  const sourceName = useMemo(() => {
    if (method === SIGN_UP_METHOD_FLOW) {
      return SIGNUP_SOURCE_NEWSMAX;
    }

    switch (source) {
      case SIGNUP_SOURCE_FACEBOOK:
        return 'Facebook';
      case SIGNUP_SOURCE_GOOGLE:
        return 'Google';
      case SIGNUP_SOURCE_HOSPICEFLYER:
        return SIGNUP_SOURCE_HOSPICEFLYER;
      case SIGNUP_SOURCE_FREDHUTCH:
        return SIGNUP_SOURCE_FREDHUTCH;
      case SIGNUP_SOURCE_LANDING:
        return 'Peacefully Landing';
      default:
    }
    
    if (!!source) return source;

    const referingMap = Object.entries(SOURCE_MAP).find(([key, value]) => {
      if (referer?.includes(value)) return true;
      return false;
    });

    return referingMap?.[0] || 'Peacefully Direct';
  }, [method, source, referer]);

  console.log("Source Name: ", sourceName);

  const handleSignUpVerifySubmit = async (code) => {
    try {
      setLoading(true);
      const user = await Amplify.Auth.confirmSignIn(mfaUser, code, "SMS_MFA");

      if (user) {
        const { promoCode = "", firstName, lastName, ...rest } = signupPayload;
        const qualtricsUserId = localStorage.getItem("qualtricsId") || qualtricsId;
        localStorage.removeItem("qualtricsId");

        const payload = {
          ...rest,
          first_name: firstName,
          last_name: lastName,
          promo_code: promoCode,
          qualtrics_user_id: qualtricsUserId,
          source: sourceName,
          term: term || '',
          register_status: "Completed",
          hs_id: hsId,
        };
        await getUserRegister(payload);

        const { UserAttributes } = await user.fetchUserData();
        const attributes = UserAttributes.reduce(
          (sm, it) => ({ ...sm, [it.Name]: it.Value }),
          {}
        );

        if (!isCompanyEmail(attributes?.email)) {
          mixpanel.identify(attributes.email);
          mixpanel.people.set({
            Email: attributes.email,
            Name: `${attributes.given_name} ${attributes.family_name}`,
            createdDate: new Date(),
            Phone: attributes.phone_number,
            Platform: "Mixpanel",
          });

          mixpanel.track("Sign Up", {
            email: attributes.email,
            firstName: attributes.given_name,
            lastName: attributes.family_name,
            phone: attributes.phone_number,
            source: sourceName,
          });

          await handleSendFBEvent(attributes);
        }

        const token = user
          .getSignInUserSession()
          .getAccessToken()
          .getJwtToken();

        dispatch(
          loginStart({
            email: signupPayload.email,
            access_token: token,
            password: signupPayload.password,
            attributes,
          })
        );
      }
    } catch (err) {
      console.log("Signup Verification Failed: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveInfo = async (values) => {
    const result = await postHSLog({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      source: sourceName,
      term: term,
      register_status: "Sign Up",
    });
    setHSId(result?.data?.data?.id);
    setInfo(values);
    setFlowStep(FLOW_STEP_SECURITY);
  };

  const handleBackInfo = () => {
    setFlowStep(FLOW_STEP_INFO);
  };

  const renderNormalForm = () => {
    return (
      <FormContainer
        validation={validationSchema}
        defaultValues={initialValues}
        onSuccess={handleFormSubmit}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Input
              fullWidth
              name="firstName"
              label="First Name"
              variant="filled"
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              fullWidth
              name="lastName"
              label="Last Name"
              variant="filled"
            />
          </Grid>

          <Grid item xs={12}>
            <MobileInput name="mobile" label="Phone Number" />
          </Grid>

          <Grid item xs={12}>
            <Input fullWidth name="email" label="Email" variant="filled" />
          </Grid>

          <Grid item xs={12}>
            <Input
              fullWidth
              name="password"
              label="Password"
              type="password"
              variant="filled"
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              fullWidth
              name="repeatPassword"
              label="Repeat Password"
              type="password"
              variant="filled"
            />
          </Grid>

          {source !== SIGNUP_SOURCE_FACEBOOK && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name="promoCode"
                label="Promo Code"
                variant="filled"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box
              display="flex"
              py={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Checkbox label={<TermLabel />} name="agree" />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
                size="large"
              >
                Get Started
              </LoadingButton>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              pt={1}
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Typography
                sx={{ mr: 0.5 }}
                component="span"
                color="text.secondary"
              >
                {`Already have an account?`}
              </Typography>

              <Typography
                color="primary.light"
                sx={{ cursor: "pointer" }}
                component={Link}
                to="/auth/login"
              >
                Sign In
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </FormContainer>
    );
  };

  const renderInfoForm = () => (
    <FormContainer
      validation={validationInfoSchema}
      defaultValues={info || initialInfoValues}
      onSuccess={handleSaveInfo}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Input
            fullWidth
            name="firstName"
            label="First Name"
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <Input fullWidth name="lastName" label="Last Name" variant="filled" />
        </Grid>

        <Grid item xs={12}>
          <Input fullWidth name="email" label="Email" variant="filled" />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Continue
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FormContainer>
  );

  const renderSecurityForm = () => (
    <FormContainer
      validation={validationSecuritySchema}
      defaultValues={initialSecurityValues}
      onSuccess={handleFormSubmit}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MobileInput name="mobile" label="Phone Number" />
        </Grid>

        <Grid item xs={12}>
          <Input
            fullWidth
            name="password"
            label="Password"
            type="password"
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            fullWidth
            name="repeatPassword"
            label="Repeat Password"
            type="password"
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
            <Button
              startIcon={<KeyboardBackspaceIcon />}
              variant="outlined"
              sx={{ marginRight: "10px" }}
              onClick={handleBackInfo}
            />

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
              size="large"
            >
              Create Account
            </LoadingButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{ textAlign: "center" }}
            component="div"
            color="#a3a3ad"
          >
            <Typography component="span">
              {"By creating an account you are agreeing to "}
            </Typography>

            <Typography
              fontWeight="500"
              color="primary.light"
              component="a"
              target="_blank"
              className="link"
              rel="noreferrer"
              href="https://www.peacefully.com/terms-services"
            >
              Terms and Conditions
            </Typography>

            <Typography component="span">{` and `}</Typography>

            <Typography
              fontWeight="500"
              color="primary.light"
              component="a"
              target="_blank"
              className="link"
              rel="noreferrer"
              href="https://www.peacefully.com/privacy-policy"
            >
              Privacy Policy.
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </FormContainer>
  );

  return (
    <>
      <Box
        width="100%"
        maxWidth={420}
        borderRadius={4}
        backgroundColor="#fff"
        sx={{
          px: { xs: 2, sm: 4 },
          py: 5,
          boxShadow: { xs: "none", sm: "0 2px 10px 0 rgba(0,0,0,.15)" },
        }}
      >
        <Box mb={3}>
          <Typography variant="h2" align="center">
            {method === SIGN_UP_METHOD_FLOW ? "Learn More" : "Sign Up for Free"}
          </Typography>
        </Box>

        {method === SIGN_UP_METHOD_NORMAL && (
          <Box mb={4}>
            <Typography align="center" color="grey.500">
              (No credit card required)
            </Typography>
          </Box>
        )}

        {method === SIGN_UP_METHOD_NORMAL && renderNormalForm()}
        {method === SIGN_UP_METHOD_FLOW && flowStep === FLOW_STEP_INFO && renderInfoForm()}
        {method === SIGN_UP_METHOD_FLOW && flowStep === FLOW_STEP_SECURITY && renderSecurityForm()}
      </Box>

      {showVerification && (
        <VerificationModal
          handleClose={handleClose}
          loading={loading}
          resendCode={handleResendCode}
          onSubmit={handleSignUpVerifySubmit}
        />
      )}
    </>
  );
};

const TermLabel = () => (
  <Typography component="div" color="#a3a3ad">
    <Typography component="span">
      {`By clicking Get Started I agree to the `}
    </Typography>

    <Typography
      fontWeight="500"
      color="primary.light"
      component="a"
      target="_blank"
      className="link"
      rel="noreferrer"
      href="https://www.peacefully.com/terms-services"
    >
      Terms and Conditions
    </Typography>

    <Typography component="span">{` and `}</Typography>

    <Typography
      fontWeight="500"
      color="primary.light"
      component="a"
      target="_blank"
      className="link"
      rel="noreferrer"
      href="https://www.peacefully.com/privacy-policy"
    >
      Privacy Policy.
    </Typography>
  </Typography>
);

export default SignUpForm;
