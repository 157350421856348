import { Box, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import Amplify from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormContainer from "../../../Components/Form/FormContainer";
import Input from "../../../Components/Form/Input";
import Checkbox from "../../../Components/Form/Checkbox";
import IconCheck from "../../../Assets/Images/icon_check.svg";

import { BASIC_FEATURES } from "../../../constants";
import PublicLayout from "../../../Layouts/PublicLayout";
import FooterFixNav from "../Component/FooterFixNav";
import VerificationModal from "./Verification";
import mixpanel from "../../../mixpanel";
import { loginStart } from "../../../Redux-Saga/Redux/account";
import { Link, useNavigate } from "react-router-dom";

const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&~/`£])[A-Za-z\d@$!%*#?&~/`£]{8,}$/;

const Login = () => {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [mfaUser, setMfaUser] = useState();
  const [credentials, setCredentials] = useState();
  const [showVerification, setShowVerification] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .nullable()
      .trim()
      .required("Email is required.")
      .matches(emailRegExp, "Please provide valid email."),
    password: Yup.string()
      .trim()
      .required("Password is required.")
      .matches(passwordRegExp, "Your password must contain 8 characters with at least one uppercase letter, one lowercase letter, one number and one special character."),
  });

  const initialValues = { email: '', password: '' };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true)
      const { email, password } = values;
      setCredentials(values);
      const awsUser = await Amplify.Auth.signIn({ username: email, password });
      setMfaUser(awsUser);

      if (awsUser.challengeName === "SMS_MFA" || awsUser.challengeName === "SOFTWARE_TOKEN_MFA") {
        setShowVerification(true);
      } else if (awsUser.challengeName === "MFA_SETUP") {
        Amplify.Auth.setupTOTP(awsUser);
      }
    } catch (err) {
      console.log('login failed', err.code);
      const errorMsg = err?.message || err?.error?.message || err?.code
      toast.error(errorMsg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false)
    }
  }

  const resendCodeSubmit = async () => {
    try {
      const { email, password } = credentials;
      const user = await Amplify.Auth.signIn({ username: email, password });
      setMfaUser(user);
      
      toast.success(`We've resent the verification code to your phone`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.error(`Failed to resend the verification code to your phone`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log('Resend code failed: ', err);
    }
  }

  const handleVerifySubmit = async (code) => {
    try {
      setLoading(true)
      const response = await Amplify.Auth.confirmSignIn(mfaUser, code, "SMS_MFA");
      const { UserAttributes } = await response.fetchUserData();
      const token = response?.getSignInUserSession().getAccessToken().getJwtToken();
      const attributes = UserAttributes.reduce((sm, it) => ({ ...sm, [it.Name]: it.Value }), {});

      mixpanel.identify(attributes.email);
      mixpanel.people.set({
        Email: attributes.email,
        Name: `${attributes.given_name} ${attributes.family_name || attributes.middle_name}`,
        createdDate: new Date(),
        Phone: attributes.phone_number,
        Platform: "Mixpanel",
      });

      mixpanel.track("Sign In", {
        email: attributes.email,
        firstName: attributes.given_name,
        lastName: attributes.family_name || attributes.middle_name,
        phone: attributes.phone_number,
        referer: account.referer,
      });

      dispatch(
        loginStart({
          email: credentials.email,
          access_token: token,
          password: credentials.password,
          attributes,
        })
      );

      if (account.errorMessage !== null) {
        console.log("Account Error Message: ", account);
        toast.error(account.errorMessage.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      const { message } = err;
      console.log('Verification Failed: ', err);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (account?.isAuthenticated) navigate("/dashboard");
  }, [account, navigate]);

  return (
    <PublicLayout>
      <Box flex={1} display="flex">
        <Box width={500} flexDirection="column" sx={{ display: { xs: 'none', lg: 'flex' }}}>
          <Box px={6} pt={9} flex={1}>
            <Typography
              variant="h1"
              color="primary"
              sx={{ mb: 3 }}
            >
              Welcome back to Peacefully!
            </Typography>

            <Typography
              color="#000"
              sx={{ fontSize: 18, mb: 8 }}
            >
              Let Peacefully help you and your family stay organized when it matters the most.
            </Typography>

            {BASIC_FEATURES.map((feature, index) => (
              <Box
                display="flex"
                alignItems="center"
                mb={3}
                key={index}
              >
                <img
                  className="auth_container-side-content-item-img"
                  src={IconCheck}
                  alt="check"
                />

                <Typography sx={{ ml: 1.5 }}>{feature}</Typography>
              </Box>
            ))}
          </Box>

          <Box py={5} pr={4}>
            <FooterFixNav />
          </Box>
        </Box>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          sx={{ bgcolor: { xs: '#fff', sm: '#f2f7f7' } }}
        >
          <Box flex={1} py={4} px={2} display="flex" justifyContent="center" alignItems="center">
            <Box
              width="100%"
              maxWidth={420}
              borderRadius={4}
              backgroundColor="#fff"
              sx={{ px: { xs: 2, sm: 4 }, py: { xs: 4, sm: 8 }, boxShadow: { xs: 'none', sm: '0 2px 10px 0 rgba(0,0,0,.15)' } }}
            >
              <Box mb={4}>
                <Typography variant="h2" align="center">Login</Typography>
              </Box>

              <Box mb={4}>
                <Typography align="center" color="#545454" sx={{ fontSize: 18 }}>
                  Please log in to your account to continue
                </Typography>
              </Box>

              <FormContainer
                validation={validationSchema}
                defaultValues={initialValues}
                onSuccess={handleFormSubmit}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="email"
                      label="Email"
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Input
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      variant="filled"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Checkbox label="Remember me" name="remember" />

                      <Typography
                        color="primary.light"
                        sx={{ cursor: 'pointer' }}
                        component={Link}
                        to={`/auth/reset${window.location.search}`}
                      >
                        Forgot Your Password?
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={loading}
                        size="large"
                      >
                        Continue
                      </LoadingButton>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
                      <Typography
                        sx={{ mr: 0.5 }}
                        component="span"
                        color="text.secondary"
                      >
                        {`Don't have an account?`}
                      </Typography>

                      <Typography
                        color="primary.light"
                        sx={{ cursor: 'pointer' }}
                        component={Link}
                        to={`/auth/signup${window.location.search}`}
                      >
                        Sign Up
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </FormContainer>
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'block', lg: 'none' }}}>
            <FooterFixNav />
          </Box>
        </Box>
      </Box>

      {showVerification && (
        <VerificationModal
          handleClose={() => setShowVerification(false)}
          onSubmit={handleVerifySubmit}
          loading={loading}
          resendCode={resendCodeSubmit}
        />
      )}
    </PublicLayout>
  );
};

export default Login;
