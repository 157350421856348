import { Checkbox as MUICheckbox, FormControl, Box, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React from 'react'
import { Controller } from 'react-hook-form'

const CheckIcon = () => (
  <Box p={0.25}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="19" rx="4.5" stroke="#A3A3AD"/>
    </svg>
  </Box>
);

const CheckedIcon = () => (
  <Box>
    <CheckBoxIcon style={{ color: 'primary.light' }} />
  </Box>
)

const Checkbox = React.memo(
  ({ name, required, parseError, label, ...rest }) => (
    <Controller
      name={name}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
        const helperText = error ? (typeof parseError === 'function' ? parseError(error) : error.message) : rest.helperText;

        return (
          <FormControl required={required} error={invalid}>
            <Box display="flex" alignItems="flex-start">
              <MUICheckbox
                color="primary"
                sx={{ color: invalid ? red[400] : undefined, p: 0 }}
                value={value}
                checked={!!value}
                name={name}
                icon={<CheckIcon />}
                checkedIcon={<CheckedIcon />}
                onChange={() => onChange(!value)}
              />
              
              {label && <Box ml={1}>{label}</Box>}
            </Box>
            {!!helperText && <Typography color="error" variant="body2" sx={{ mt: 0.5, px: 0.5 }}>{helperText}</Typography>}
          </FormControl>
        )
      }}
    />
  )
)

export default Checkbox
