import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#22427D",
      light: "#4b6eae",
      dark: "#011842",
      error: "#d32f2f"
    },
    secondary: {
      main: green[500]
    },
    success: {
      main: "#63b8a7"
    },
    grey: {
      0: "#000000",
      400: "#323233",
      450: "#4D4D4D",
      500: "#797979",
      600: "#7a7a7a",
      700: "#545454",
      900: "#1A1A1A",
      1000: "#D7D7D7",
      1100: "#ABABAB"
    }
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h1: {
      fontSize: "42px",
      fontWeight: 700
    },
    h2: {
      fontSize: "32px",
      fontWeight: 500
    },
    h3: {
      fontSize: "28px",
      lineHeight: "38px",
      fontWeight: 700
    },
    h4: {
      fontSize: "24px",
      fontWeight: 700
    },
    h5: {
      fontSize: "22px",
      fontWeight: 700
    },
    h6: {
      fontSize: "16px",
      fontWeight: 700
    },
    h7: {
      fontSize: "14px",
      fontWeight: 700
    },
    h8: {
      fontSize: "10px",
      fontWeight: 400
    },
    h9: {
      fontSize: "10px",
      fontWeight: 400
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: 25
        },
        sizeLarge: {
          height: 50,
          fontSize: 16
        },
        containedPrimary: {
          backgroundColor: "#4b6eae",
          ":hover": {
            backgroundColor: "#27457c"
          },
          ":active": {
            backgroundColor: "#69789b"
          }
        },
        outlinedPrimary: {
          ":hover": {
            backgroundColor: "#4b6eae",
            color: "#ffffff",
            borderColor: "#4b6eae"
          },
          ":active": {
            backgroundColor: "#27457c",
            color: "#ffffff",
            borderColor: "#27457c"
          }
        }
      }
    }
  }
});

export default theme;
