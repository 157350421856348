import { LoadingButton } from "@mui/lab";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import * as Yup from "yup";
import FormContainer from "../../../Components/Form/FormContainer";
import Input from "../../../Components/Form/Input";
import { Link } from "react-router-dom";

const Verification = ({
  handleClose,
  onSubmit,
  loading,
}) => {
  const validationSchema = Yup.object().shape({
    code: Yup
      .string()
      .required("Code is required")
      .matches(/^\d{6}$/, "Code must be a six-digit number"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .required("Password is required")
        .oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password should be same"
        ),
    }),
  });

  const initialValues = {
    code: "",
    password: "",
    confirmPassword: ""
  }

  return (
    <Dialog open={true} onClose={handleClose} PaperProps={{ sx: { borderRadius: 4 } }}>
      <Box position="relative" sx={{ width: 500, px: 3, py: 6 }}>
        <Box
          display="flex"
          position="absolute"
          top={0}
          left={0}
          right={0}
          p={2}
          justifyContent="flex-end"
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: 32 }} />
        </Box>

        <Box mb={3} display="flex" alignItems="center" textAlign="center" justifyContent="center">
          <Typography
            variant="h2"
            align="center"
            color="#000"
            component={Link}
            to="/auth/reset"
          >
            Forgot your Password?
          </Typography>
        </Box>

        <Typography
          align="center"
          mb={5}
          color="#545454"
        >
          We've sent you a SMS code to help you reset your password.
        </Typography>

        <FormContainer
          defaultValues={initialValues}
          validationSchema={validationSchema}
          onSuccess={(values) => onSubmit(values)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                fullWidth
                name="code"
                label="Verification Code"
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                fullWidth
                name="password"
                type="password"
                label="Password"
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                fullWidth
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <Box px={8}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={loading}
                  size="large"
                >
                  Reset Password
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </FormContainer>
      </Box>
    </Dialog>
  );
};

export default Verification;
