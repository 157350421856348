import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Drawer, Toolbar } from "@mui/material";
import { debounce } from "lodash";
import { useIntercom } from 'react-use-intercom';

import Header from "./Header";
import Sidebar from "./Sidebar";
import AuthenticatedFooter from "./AuthenticatedFooter";
import IframLoading from "./IframeLoading";

const PrivateLayout = ({ children }) => {
  const { user, sessionInfo, isAuthenticated } = useSelector((state) => state.account);
  const { currentUser = {} } = sessionInfo;
  const {
    qualtrics_user_id: qualtricsUserId,
    email,
    id: userId,
    isLoading: isLoadingUser,
  } = currentUser;
  const isLoading = isLoadingUser || !email || !userId || !qualtricsUserId

  const [toggle, setToggle] = useState(true);
  const navigate = useNavigate();
  const { boot, showMessages, shutdown } = useIntercom();

  useEffect(() => {
    const handleApiRequest = debounce(async (data) => {
      switch (data.mode) {
        case 'owner-create':
        default:
      }
    }, 1000);

    const eventHandler = (data) => {
      if (data?.data?.type === 'api') {
        handleApiRequest(data?.data);
      }
    }

    window.addEventListener("message", eventHandler);
    return () => window.removeEventListener("message", eventHandler);
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (user) {
      boot({ name: `${user.first_name} ${user.last_name}`, email: user.email });
      showMessages();
    }

    return () => {
      shutdown();
    }
    // eslint-disable-next-line
  }, [user]);

  if (!isAuthenticated) navigate("/auth/login");

  return (
    <Box>
      <Header toggle={toggle} onChangeToggle={setToggle} />

      <Box
        flex={1}
        display="flex"
        sx={{ paddingTop: '68px' }}
      >
        <Drawer
          anchor="left"
          open={toggle}
          variant="persistent"
          onClose={() => setToggle(false)}
          sx={{
            width: { xs: 0, md: 256 },
            [`& .MuiDrawer-paper`]: { width: 256, boxSizing: 'border-box', zIndex: 1000 },
          }}
        >
          <Toolbar sx={{ height: 68 }} />
          <Sidebar />
        </Drawer>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          position="relative"
          id="board"
          sx={{
            height: { xs: 'calc(100vh - 61px)', md: 'calc(100vh - 68px)' },
            overflow: 'auto'
          }}
        >
          <Box flex={1} display="flex" flexDirection="column">
            {isLoading ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : children}
          </Box>

          <IframLoading isLoading={isLoading} />
          <AuthenticatedFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
