import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteProfile } from "../../../Services/User";
import { getLogout, getSessionInfoStart } from "../../../Redux-Saga/Redux/account";
import { toast } from "react-toastify";

import Amplify from "aws-amplify";
import { encryptStorage } from "../../../Components/Helpers/commonMethods";
import { Button, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ProfileFooter = () => {
  useEffect(() => {
    encryptStorage.getItem("user");
  }, []);

  const [loading, setLoading] = useState(false);
  const { token, sessionInfo } = useSelector((state) => state.account);
  const { currentUser = {} } = sessionInfo;
  const dispatch = useDispatch();

  const handleDeleteMyProfile = useCallback(async () => {
    if (token) {
      setLoading(true);
      await Amplify.Auth.deleteUser();
      await deleteProfile(token);
      setLoading(false);
      dispatch(getLogout());
    }
  }, [token, dispatch]);

  const handleCsvDownload = useCallback(() => {
    if (currentUser?.user_qualtcis_csv_url) {
      window.open(currentUser?.user_qualtcis_csv_url);
    } else {
      dispatch(getSessionInfoStart(token));
      toast.error("We are still generating the CSV file for download", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [currentUser, dispatch, token]);

  return (
    <Box py={3}>
      <Box mb={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#000" }}>
          Account Closure
        </Typography>
      </Box>

      <Box>
        <Button variant="contained" onClick={handleCsvDownload} sx={{ mr: 1 }}>
          Download Data
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="inherit"
          onClick={handleDeleteMyProfile}
        >
          Delete Account
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ProfileFooter;
