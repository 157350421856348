import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Drawer, IconButton, Stack, Toolbar, Typography } from "@mui/material";

import { useSelector } from "react-redux";

const Header = ({ signUpUrl, signInUrl }) => {
  const [open, setOpen] = useState(false);

  const { isAuthenticated } = useSelector((state) => state.account);

  const LinkSignUp = () => {
    window.location.href = signUpUrl;
  }

  return (
    <>
      <AppBar color="inherit" component="nav">
        <Toolbar sx={{ py: 1.25, minHeight: '48px !important', bgcolor: '#061f4d' }}>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <Link
              to={isAuthenticated ? "/dashboard" : "/"}
              onClick={() => setOpen(false)}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                color="white"
                fontSize={32}
                fontWeight={700}
                fontFamily="EB Garamond,serif"
              >
                Peacefully
              </Typography>
            </Link>
          </Box>

          <IconButton
            color="inherit"
            edge="start"
            onClick={() => setOpen(!open)}
            sx={{ color: 'white', display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ fontSize: 32 }} />
          </IconButton>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Stack direction="row" alignItems="center" spacing={4}>
              <Typography color="white" fontWeight={500}>
                <a href="https://peacefully.com/employers/" className="nav-link">
                  Employers
                </a>
              </Typography>

              <Typography color="white" fontWeight={500}>
                <a className="nav-link" href="https://guide.peacefully.com">
                  Resources
                </a>
              </Typography>
              
              <Typography color="white" fontWeight={500}>
                <a
                  className="nav-link"
                  href="mailto:hello@peacefully.com"
                  target=""
                >
                  Contact Us
                </a>
              </Typography>

              <Typography color="white" fontWeight={500}>
                <a
                  className="nav-link"
                  href={signInUrl}
                >
                  Login
                </a>
              </Typography>
              
              <Button
                variant="outlined"
                size="large"
                onClick={LinkSignUp}
                sx={{ px: 5, color: 'white', borderColor: 'white' }}
              >
                Get Started
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        <Stack direction="column" alignItems="center" spacing={4} py={5}>
          <Typography color="primary" fontWeight={500}>
            <Link to="/employers/learn-more" className="nav-link">
              Employers
            </Link>
          </Typography>

          <Typography color="primary" fontWeight={500}>
            <a className="nav-link" href="https://guide.peacefully.com">
              Resources
            </a>
          </Typography>
          
          <Typography color="primary" fontWeight={500}>
            <a
              className="nav-link"
              href="mailto:hello@peacefully.com"
              target=""
            >
              Contact Us
            </a>
          </Typography>

          <Typography color="primary" fontWeight={500}>
            <a
              className="nav-link"
              href="https://app.peacefully.com/auth/login"
              onClick={() => setOpen(false)}
            >
              Login
            </a>
          </Typography>
          
          <Button
            variant="outlined"
            size="large"
            onClick={LinkSignUp}
            sx={{ px: 5 }}
          >
            Get Started
          </Button>
        </Stack>
      </Drawer>
    </>
  );
};

export default Header;
