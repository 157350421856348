import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userUpdateProfile } from "../../Services/User";
import { toast } from "react-toastify";
import Amplify from "aws-amplify";
import { encryptStorage } from "../../Components/Helpers/commonMethods";
import { updateUserProfile } from "../../Redux-Saga/Redux/account";
import PhotoUploadModal from "../../Components/modals/PhotoUploadModal";
import initials from "initials";
import IconEdit from "../../Assets/Images/icon_edit.svg";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import FormContainer from "../../Components/Form/FormContainer";
import Input from "../../Components/Form/Input";

const ProfileUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [photoEdit, setPhotoEdit] = useState(false);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const handleSubmitUpdate = async (values) => {
    setLoading(true);
    const data = {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
    };
    console.log(data);
    const user = await Amplify.Auth.currentAuthenticatedUser();
    console.log("User info check", user);
    const udpateUserCognito = await Amplify.Auth.updateUserAttributes(user, {
      given_name: values.fname,
      family_name: values.lname,
    });
    console.log("UdpateUserCognito", udpateUserCognito);
    if (udpateUserCognito === "SUCCESS") {
      userUpdateProfile(account.token, data)
        .then(function (res) {
          console.log("userUpdateProfile:", res);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          const prepareData = {
            ...account.user,
            last_name: values.lname,
            first_name: values.fname,
          };
          dispatch(updateUserProfile(prepareData));
          encryptStorage.setItem("user", prepareData);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          toast.error(error.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      toast.error("Something went wrong please contact support", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const initialValues = {
    fname: account?.user?.first_name || "",
    lname: account?.user?.last_name || "",
    email: account?.user?.email || "",
    phone: account?.user?.mobile || "",
  };

  if (!account) return null;

  return (
    <Box py={3}>
      <FormContainer
        defaultValues={initialValues}
        onSuccess={handleSubmitUpdate}
      >
        <Box mb={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#000" }}>
            Personal Information
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Box
              display="flex"
              alignItems="flex-end"
              width={180}
              sx={{ position: "relative" }}
            >
              {account?.user?.avatar_url ? (
                <Box
                  sx={{
                    backgroundImage: `url(${account?.user?.avatar_url})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  width={180}
                  height={180}
                  borderRadius={90}
                  overflow="hidden"
                />
              ) : (
                <Box
                  width={180}
                  height={180}
                  borderRadius={90}
                  overflow="hidden"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="success.main"
                  color="white"
                >
                  {initials(
                    `${account?.user?.first_name} ${account?.user?.last_name}`
                  )}
                </Box>
              )}

              <Box sx={{ position: "absolute", bottom: "0px", right: "0px" }}>
                <IconButton onClick={() => setPhotoEdit(true)}>
                  <img src={IconEdit} alt="" />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Input
                  fullWidth
                  name="fname"
                  variant="filled"
                  label="First Name"
                />
              </Grid>

              <Grid item xs={6}>
                <Input
                  fullWidth
                  name="lname"
                  variant="filled"
                  label="Last Name"
                />
              </Grid>

              <Grid item xs={6}>
                <Input
                  fullWidth
                  name="email"
                  variant="filled"
                  label="Email"
                  disabled
                  readOnly
                />
              </Grid>

              <Grid item xs={6}>
                <Input
                  fullWidth
                  name="phone"
                  variant="filled"
                  label="Phone Number"
                  disabled
                  readOnly
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
            >
              Save Changes
            </LoadingButton>
          </Grid>
        </Grid>
      </FormContainer>

      <PhotoUploadModal open={photoEdit} onClose={() => setPhotoEdit(false)} />
    </Box>
  );
};

export default ProfileUpdate;
