import React from "react";
import { Button } from "@mui/material";

import Features from "./Features";
import Review from "./Reviews";
import CallAction from "./CallAction";
import Process from "./Process";
import PublicLayout from "../../Layouts/PublicLayout";
import StressFree from "./StressFree";
import Header from "./Header";
import Footer from "./Footer";
import "./home_old.scss";

const FredHutch = () => {
  const signUpUrl = 'https://app.peacefully.com/auth/signup?promo=1&utm_source=fredhutch';
  const signInUrl = 'https://app.peacefully.com/auth/login';

  return (
    <PublicLayout>
      <Header signUpUrl={signUpUrl} signInUrl={signInUrl} />
      <section className="hero_banner_old">
        <div className="hero_banner_old-content">
          <h1>Peace of mind for you and your family</h1>
          <p>
            A caring solution to help you plan for the future and settle affairs
            after a loss.
          </p>
          <Button
            size="large"
            variant="contained"
            component={"a"}
            className="custom_btn"
            href={signUpUrl}
          >
            Get Started
          </Button>
        </div>
      </section>

      <Process />
      <StressFree signupUrl={signUpUrl} />
      <Features />
      <CallAction />
      <Review />
      <Footer signInUrl={signInUrl} />
    </PublicLayout>
  );
};

export default FredHutch;
